<template>
  <!-- 대결지정 -->
  <li :class="['area_instead', { on: isOpen }]">
    <!-- <div class="wrap_btn"> -->
    <!-- <div class="pos_left info_instead"> -->
    <a
      v-if="delegateFlag == '0'"
      href="javascript:void(0);"
      class="link_txt"
      @click.prevent="delegatePopup"
      >대결지정</a
    ><!-- 2019-07-10 대결지정 / 대결위임 silver(오승헌) / 대결 보류 / 등의 문구가 들어갈 수 있음 -->
    <a
      v-else-if="delegateFlag == '2'"
      href="javascript:void(0);"
      class="link_txt"
      @mouseover="mouseover"
      @mouseout="mouseout"
      >대결위임 {{ delegate.delegateAccountId }} ({{ delegate.delegateEmpName }})</a
    >
    <a
      v-else-if="delegateFlag == '1'"
      href="javascript:void(0);"
      class="link_txt"
      @mouseover="mouseover"
      @mouseout="mouseout"
      >대결보류</a
    >
    <a
      v-else-if="delegateFlag == '9'"
      href="javascript:void(0);"
      class="link_txt"
      @mouseover="mouseover"
      @mouseout="mouseout"
      >대결예정</a
    >
    <!-- <div :class="[{ open: isOpen }, 'box_instead']" @mouseover="mouseover" @mouseout="mouseout">
            <dl v-if="delegate.delegateFlag == 1 || delegate.delegateFlag == 2" class="list_instead">
              <dt>대결방법</dt>
              <dd v-if="delegate.delegateFlag == 2">위임</dd>
              <dd v-if="delegate.delegateFlag == 1">보류</dd>
              <dt v-if="delegate.delegateFlag == 2">위임자</dt>
              <dd v-if="delegate.delegateFlag == 2">{{ delegate.delegateEmpName }}({{ delegate.delegateAccountId }})</dd>
              <dt v-if="delegate.delegateFlag == 2">대결기간</dt>
              <dt v-if="delegate.delegateFlag == 1">보류기간</dt>
              <dd>{{ delegate.fromDate }} ~ {{ delegate.toDate }}</dd>
            </dl>
          </div> -->
    <!-- </div> -->
    <!-- <div class="pos_right">
          <button type="button" class="btn_comm type_small bg_type3" @click.prevent="updateDelegate">수정</button>
          <button type="button" class="btn_comm type_small bg_type2" @click.prevent="confirmDeleteDelegate">해제</button>
        </div> -->
    <!-- </div> -->
  </li>
</template>

<script>
import axios from "axios";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
import Vue from "vue";

Vue.prototype.$DelegateBus = new Vue();

export default {
  name: "HeaderExtend",
  mixins: [CommLayerMixin, CommToastMixin, CommMomentMixin],
  data() {
    return {
      isOpen: false,
      delegateFlag: "0",
      delegate: {
        delegateId: "",
        empNo: "",
        delegateFlag: "0",
        delegateEmpNo: "",
        reason: "",
        fromDate: "",
        toDate: "",
        useYn: "",
      },
      today: this._getMoment("YYYYMMDD"),
      initDelegate: {
        delegateId: "",
        empNo: "",
        delegateFlag: "0",
        delegateEmpNo: "",
        reason: "",
        fromDate: "",
        toDate: "",
        useYn: "",
      },
    };
  },
  created() {
    this.selectDelegate();
  },
  methods: {
    selectDelegate() {
      axios
        .post("/api/selectDelegate", {})
        .then((res) => {
          if (!res.data || res.data.toDate < this.today) {
            this.delegateFlag = "0";
          } else if (res.data.fromDate > this.today) {
            this.delegateFlag = "9";
            this.delegate = res.data;
          } else {
            this.delegateFlag = res.data.delegateFlag;
            this.delegate = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mouseover() {
      this.isOpen = true;
    },
    mouseout() {
      this.isOpen = false;
    },
    delegatePopup(type) {
      this.isOpen = false;
      let layer = {};
      layer.header = "대결 지정";
      layer.type = "popup";
      layer.componentFile = "header/PopDelegate.vue";
      if (type === "update") {
        layer.props = { curDelegate: { ...this.delegate } };
      } else {
        layer.props = { curDelegate: {} };
      }
      this._showLayer(layer, this.selectDelegate);
    },
    updateDelegate() {
      this.delegatePopup("update");
    },
    confirmDeleteDelegate() {
      const layer = {};
      layer.type = "confirm";
      layer.order = 2;
      layer.header = "대결지정 해제";
      layer.content = "대결지정을 해제하시겠습니까?";
      this._showLayer(layer, this.deleteDelegate);
    },
    deleteDelegate() {
      axios
        .post("/api/deleteDelegate", { delegateId: this.delegate.delegateId })
        .then((res) => {
          this.delegate = { ...this.initDelegate };
          this.delegateFlag = "0";
          this.toast("대결지정을 해제했습니다.");
        })
        .catch((error) => {
          console.log(error);
        });
      this.isOpen = false;
    },
    toast(message) {
      let toast = {};
      toast.content = message;
      this._showToast(toast);
    },
  },
};
</script>
